//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { MessageBox } from "mint-ui";
import { miceService } from "@/service/miceService";
export default {
  activated: function() {},
  created: function() {
    var session = sessionStorage.getItem("action");
    if (session == "search" || !session) {
      this._data.comeIn = false;
    } else {
      this._data.comeIn = true;
    }
    var self = this;
    miceService.mshopGet(this.$route.query).then(res => {
      self._data.shopData = res.data;
    });
  },
  data: function() {
    return {
      shopData: "",
      comeIn: null,
      touchModel: null
    };
  },
  methods: {
    moveStart: function(event) {
      var touch = event.touches[0];
      this._data.touchModel = {
        startY: touch.pageY,
        startX: touch.pageX,
        endX: "",
        endY: ""
      };
    },
    moveIng: function($event) {
      console.log($event);
      var touch = event.touches[0];
      this._data.touchModel.endX = touch.pageX;
      this._data.touchModel.endY = touch.pageY;
    },
    moveEnd: function(event) {
      console.log(event);
      var to = this._data.touchModel;
      if (to.startX - to.endX < -70 && to.endX) {
        this.returnBack();
      }
    },
    preSelected() {
        var selectedShop = JSON.parse(sessionStorage.getItem("selectedShop"));
        if (selectedShop&&selectedShop.isWarnShop){
          var messageBox = MessageBox({
            title: '提示',
            message: '您选择的商户属于公司的高风险商户，存在较大违规风险。使用该商户的消费，公司将会进行持续性重点核查，请慎重选择使用，谢谢！',
            showCancelButton: true
          });
          return  messageBox 
        } 
        return Promise.resolve('confirm')        
    },
    doSelected: function() {
      var tenantcode = sessionStorage.getItem("tenantcode")
      this.preSelected().then(action => {
        if(action != 'confirm')return;
        if(tenantcode == 'merck') {
          MessageBox({
            title: '提示',
            message: '请您确认已自行联系餐厅预订',
            showCancelButton: true
          }).then(action => {
            if(action != 'confirm')return;
            this.closeWebview();
          })          
        }else {
            this.closeWebview();
        }
      })   
    },
    closeWebview() {
      var shopData = this._data.shopData;
      var sourceCode = "";
      switch (shopData.platform) {
        case 0:
          sourceCode = "Dianping";
          break;
        case 1:
          sourceCode = "XiaoMiShu";
          break;
        case 2:
          sourceCode = "KouBei";
          break;
      }
      Object.assign(shopData, {
        isExtra: true,
        shopRowId: shopData.rowId,
        // shopName: shopData.shopName,
        sourceCode: sourceCode
      });
      if (shopData.hasOwnProperty("payProfile")) {
        delete shopData.payProfile;
      }
      this.iJsBridge.setResult({ postData: shopData });
      this.iJsBridge.close();
    },
    goMap: function() {
      console.log(this._data.shopData);
      this.$router.push({
        name: "Map",
        query: {
          N: this._data.shopData.fullName,
          X: this._data.shopData.el,
          Y: this._data.shopData.nl
        }
      });
    },
    returnBack: function() {
      this.$root.goStatus = -1;
      this.$router.go(-1);
    },
    getPayModes: function(data) {
      let payModes = {
        shanhui: false,
        alipay: false,
        wxpay: false,
        meican: false,
        xmspay: false,
        koubei: false,
        dadian: false
      };
      let item = data.payProfile;
      if (!item) {
        return payModes;
      }
      let modes = JSON.parse(item).PayModes;
      for (let i = 0; i < modes.length; i++) {
        switch (modes[i]) {
          case 1:
            payModes.shanhui = true;
            break;
          case 2:
            payModes.alipay = true;
            break;
          case 3:
            payModes.wxpay = true;
            break;
          case 4:
            payModes.meican = true;
            break;
          case 5:
            payModes.xmspay = true;
            break;
          case 6:
            payModes.koubei = true;
            break;
        }
      }
      return payModes;
    }
  }
};
